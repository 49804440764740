import React, { useContext } from "react"
import { TileWrapper, TitleWrapper, TileBackside, PolaroidFrame, PolaroidImg, SepiaFilter } from "./polaroid-tile.style"
import Banderole from "../../../../shared/src/components/slide/banderole"
import { MovingContext } from "../../../../shared/src/components/card-deck/card"
import "./fonts.scss"
import { LangContext, Linker } from "gatsby-source-dek-wp"
import { replaceUmlauts } from "../../../../shared/src/modules/replace-umlauts"

const PhotoTile = ({
  // imageSrcFull,
  imageSrc,
  title,
  teaser,
  flip,
  href,
  category = {},
  flipped,
  unpublished,
  small
}) => {
  const moving = useContext(MovingContext)
  const onClick = (e) => {
    // console.log("CLICKED. moving?", moving)
    if (moving) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  const lang = useContext(LangContext)
  const displayTitle = replaceUmlauts(title)
  return (
    <TileWrapper className={small ? "small" : ""}>
      <PolaroidFrame />
      <PolaroidImg src={imageSrc}>
        <SepiaFilter />
      </PolaroidImg>
      {unpublished && <Banderole>unpublished</Banderole>}
      <TitleWrapper langId={lang.id}>
        <Linker to={href} onClick={onClick} isInternal={href[0] === "/"}>
          {displayTitle}{"\u00A0"}→
        </Linker>
      </TitleWrapper>
      <TileBackside></TileBackside>
    </TileWrapper>
  )
}

export default PhotoTile
