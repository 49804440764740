import styled from "styled-components"
import { breakpointMobile, fontSizeH0, fontSizeH3 } from "../../../../shared/src/components/app/globals.module.scss"

export const Wrapper = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: ${p => p.topOffset ? `calc(100% - ${p.topOffset}px)` : "100%"};
  background: url(${p => p.image}), rgba(0,0,0,0.2);
  background-blend-mode: darken;;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  z-index: 1;
  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0,  0.4));
    box-shadow: inset 0 0 150px rgba(0, 0, 0, 0.7);
    pointer-events: none;
  }
`

export const Title = styled.h1`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: .5em;
  font-size: ${fontSizeH0 || "3.815em"};
  font-weight: 400;
  margin: 0;
  line-height: 0.9em;
  width: 68%; // 30%
  text-transform: uppercase;
  font-family: "Molot", "Roboto Condensed";
  z-index: 2;
    // border: 1px dashed red;
  @media screen and (max-width: ${breakpointMobile}) {
    font-size: ${fontSizeH3};
    width: 100%;
    padding: .25em;
  }
  .menu-open & {
    display: none;
  }
`
