import dekoderLogoWhite from "../shared/src/images/logos/dekoder-white.svg"

export const siteLogos = [
  { 
    src: dekoderLogoWhite, 
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]"
    }
  }
]