import React from "react"
import styled from "styled-components"
import LeadImgSSR from "../lead-img/lead-img-ssr"
import { SiteLogo } from "../../../plugins/gatsby-source-dek-wp/components/site-logo"
import { siteLogos } from "../../../site-logos"
// import scss for font definitions
import "../../../../shared/src/components/app/globals.module.scss"

const SocialImageWrapper = styled.div`
  width: 1200px;
  height: 630px;
`

const Header = styled.div`
  height: 50px;
  background-color: black; // $menu-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto Condensed";
`

const LangIndicator = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;

  color: #fff !important;
  letter-spacing: 4px;
  padding-right: 15px;
  text-transform: uppercase;

  font-size: 16px;
  font-weight: 700;
`

const SocialImage = ({ pageContext = {} }) => {
  const { title, titleVideoPoster, langId } = pageContext
  console.log("RENDERING SOCIAL IMG", title, titleVideoPoster)
  const slideContent = { image: titleVideoPoster, title }
  return (
    <SocialImageWrapper>
      <Header>
        <SiteLogo logos={siteLogos} langId={langId} />
        <LangIndicator>{langId}</LangIndicator>
      </Header>
      <LeadImgSSR slide={slideContent} topOffset={50} />
    </SocialImageWrapper>
  )
}

export default SocialImage
