exports.categoryIds = [7921, 7929]

exports.siteMetaInfo = {
  de: {
    title: "KRACH 1991",
    subtitle: "Zusammenbruch und Aufbruch: Zeitzeugen erinnern sich",
    description: "Zusammenbruch und Aufbruch: Zeitzeugen erinnern sich",
    imageUrl: "https://specials.dekoder.org/content/uploads/2021/06/zwangsarbeit_social_de.png",
    slug: "/de"
  },
  ru: {
    title: "1991. КРАХ",
    subtitle: "Переворот и народ: вспоминают очевидцы",
    description: "Переворот и народ: вспоминают очевидцы",
    imageUrl: "https://specials.dekoder.org/content/uploads/2021/06/zwangsarbeit_social_de.png",
    slug: "/ru"
  }
}

exports.socialImgComp = require.resolve("./src/components/social-image/social-image.js")

exports.siteMetadata = {
  title: `KRACH 1991`,
  description: `Zusammenbruch und Aufbruch: Zeitzeugen erinnern sich`,
  siteUrl: `https://krach.dekoder.org`,
  author: `dekoder.org`
}

exports.pathPrefix = ""
exports.faviconPath = `../shared/src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = "UA-66707787-9"
