import styled from "styled-components"
import { breakpointMobile, articleRed } from "../../../../shared/src/components/app/globals.module.scss"
import polaroidFrame from "./polaroid-frame.png"

export const PolaroidFrame = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(${polaroidFrame});
  background-size: cover;
  background-position: 50% 0;
  z-index: 1;
  // opacity: 0.5;
`

export const TileWrapper = styled.div`
  // border:1px dashed red;
  overflow: hidden;
  position: relative;
  width: 360px;
  height: 440px;

  @media screen and (max-width: ${breakpointMobile}) {
    width: 320px;
    height: 390px;
  }

  box-shadow: 8px 4px 20px 0px rgba(0, 0, 0, 0.7);
  background: transparent;
  transform-style: preserve-3d;
  // backface-visibility: hidden;
`

export const PolaroidImg = styled.div`
  position: absolute;
  top: 5%;
  left: 6%;
  width: 89%;
  height: 75%;
  z-index: 0;
  // border: 1px dashed blue;
  background: url(${p => p.src}), black;
  background-size: cover; 
  background-position: 50% 50%;
  &::after {
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.7);
    background-image: radial-gradient(rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0,  0.3));
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const TitleWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 7%;
  // transform: translateX(-50%);
  // max-width: 90%;
  width: 86%;
  height: 21%;
  text-align: left;

  backface-visibility: hidden;
  // border: 1px dashed green;
  display: flex;
  align-items: center;

  a, a:active, a:hover, a:visited {
    color: ${articleRed};
    font-family: "Molot"; // ${p => p.langId === "ru" ? "Zametka" : "PermanentMarker"}, "Roboto Condensed"; // "Roboto Condensed"; // "PermanentMarker";
    
    // font-style: ${p => p.langId === "ru" ? "italic" : "normal"};
    // font-weight: ${p => p.langId === "ru" ? 800 : 400};
    // letter-spacing: ${p => p.langId === "ru" ? "0.05em" : "0"};
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 0.9em;
    // background: black;
    // box-shadow: 5px 0 0 black, -5px 0 0 black;
  }
`

export const TileBackside = styled.div`
  transform: translateZ(-2px) rotateY(180deg);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background: white;
  transform-style: preserve-3d;
  backface-visibility: hidden;
`

export const SepiaFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
  background: linear-gradient(
      to bottom,
      rgba(255, 145, 0, 0.2) 0%,
      rgba(255, 230, 48, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(255, 0, 0, 0.6) 0%, rgba(255, 0, 0, 0) 35%);
  ${(p) =>
    p.fuzzy &&
    `
    opacity: 1;
    border: 2px solid black;
    box-shadow: inset 0 0 15px 15px rgba(0, 0, 0, 1);
    @media screen and (max-width: ${breakpointMobile}) {
      border: 1px solid black;
      box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 1);
    }
  `}
`