import { articleRed } from "../shared/src/components/app/globals.module.scss"
// import dekoderLogoBlack from "../shared/src/images/logos/dekoder-black.svg"

import bg from "./src/images/soviet-wallpaper2.jpeg"

import PolaroidTile from "./src/components/polaroid-tile/polaroid-tile"
import LeadImg from "./src/components/lead-img"

export const CustomTile = PolaroidTile
export const CustomLead = LeadImg

export const tileSize = {
  width: 360,
  height: 440
}

export const maxPerRow = 2

/* export const flexHook = ({ x, y, i, itemsPerRow }) => {
  // shift 1st and 3rd tile up on desktop
  return (itemsPerRow === 3 && (i === 0 || i === 2))
    ? [x, y - 70]
    : [x, y]
} */

export const backgroundImage = bg
export const bgNotFixed = false
export const bgBlendColor = "rgba(0, 0, 0, 0.4)"
export const bgPosition = "0 0"

export const bgCredit = {
  de: 'Tapete © <a href="http://www.sovietinnerness.com">Soviet Innerness</a>',
  ru: 'Обои © <a href="http://www.sovietinnerness.com">Soviet Innerness</a>',
}

export const titleFont = "Molot"

export const socialImgDefault = {
  de: "https://specials.dekoder.org/content/uploads/2021/08/krach_social_de.png",
  ru: "https://specials.dekoder.org/content/uploads/2021/08/krach_social_ru.png"
}

export { siteMetaInfo, siteMetadata, restApi, categoryIds } from "./meta-data-node"

export { siteLogos } from "./site-logos"

export const categories = [
  {
    id: 0,
    key: "article",
    color: articleRed,
    selected: true,
    label: {
      de: "Geschichten",
      ru: "Истории"
    }
  },
  {
    id: 1,
    key: "gnose",
    color: articleRed, // gnoseBlue,
    selected: true,
    label: {
      de: "Hintergründe",
      ru: "Контекст"
    }
  },
  {
    id: 2,
    key: "special",
    color: articleRed, // specialContentColor,
    selected: true,
    label: {
      de: "Spezial",
      ru: "Спец"
    }
  },
  {
    id: 3,
    key: "about",
    color: "black",
    selected: true,
    hide: true,
    label: {
      de: "Über das Projekt",
      ru: "О проекте"
    }
  },
  {
    id: 4,
    key: "default",
    color: "black",
    selected: true,
    label: {
      de: "default",
      ru: "default"
    }
  }
]

export const projectPartners = [
]

export const mediaPartners = [
]
